import { FocusEventHandler, ReactNode, useState } from 'react';
import {
  useCombobox,
  UseComboboxReturnValue,
  UseComboboxProps,
} from 'downshift';
import { matchSorter } from 'match-sorter';
import {
  Box,
  List,
  ListItem,
  ListItemProps,
  Input,
  ListProps,
  FormLabel,
  FormControl,
  InputProps,
  BoxProps,
} from '@chakra-ui/react';

export interface Option {
  id: number | string;
  name: string;
  value?: string;
  tags?: string[];
  type?: string;
}

export interface RenderProps
  extends Pick<
    UseComboboxReturnValue<Option>,
    'getInputProps' | 'getLabelProps' | 'openMenu'
  > {
  getOuterProps: UseComboboxReturnValue<Option>['getComboboxProps'];
  items: Option[];
  disabled: boolean;
}

interface Props
  extends Partial<Pick<UseComboboxProps<Option>, 'itemToString'>> {
  items: Option[];
  children?: (props: RenderProps) => Omit<ReactNode, 'string'>;
  onSelect?: (selected: null | undefined | Option) => void;
  listProps?: null | ListProps;
  itemProps?: null | ListItemProps;
  containerProps?: null | BoxProps;
  name?: string;
  labelText?: ReactNode;
  selectedItems?: Option[];
  renderOption?: (option: Option, searchTerm: string) => ReactNode;
  disabled?: boolean;
  placeholder?: string;
  hideOptionsOnFocus?: boolean;
  isSingleSelect?: boolean;
  defaultValue?: string;
}

// Downshift has any
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */

const defaultItemToString: UseComboboxProps<Option>['itemToString'] = (
  item: Option | null,
) => (item && item?.name) || '';

const filterItemsToShow = (items: Option[], value = '') =>
  matchSorter(items, value, {
    keys: ['name', 'tags'],
    threshold: matchSorter.rankings.CONTAINS,
  });

interface State {
  items: Option[];
  value: string;
}

interface GetFilteredItemsRequired {
  items: Option[];
  itemToString: NonNullable<UseComboboxProps<Option>['itemToString']>;
}

const getFilteredItems = (
  { items: allItems, itemToString }: GetFilteredItemsRequired,
  value?: string,
  currentItems?: Option[],
) =>
  filterItemsToShow(
    !currentItems
      ? allItems
      : allItems?.filter(
          (item) =>
            !currentItems.map(itemToString).includes(itemToString(item)),
        ),
    value,
  );

const AutoSuggest = ({
  items = [],
  children,
  onSelect,
  containerProps = null,
  listProps = null,
  itemProps = null,
  name,
  labelText,
  itemToString = defaultItemToString,
  renderOption,
  selectedItems,
  disabled = false,
  hideOptionsOnFocus = false,
  placeholder,
  isSingleSelect = false,
  defaultValue = '',
}: Props) => {
  const [{ items: inputItems, value }, setState] = useState<State>({
    items: getFilteredItems(
      { items, itemToString },
      defaultValue,
      selectedItems,
    ),
    value: defaultValue,
  });

  const onItemSelect = (selectedItem: Option, closeMenu: () => void) => {
    // Callback to be 'closeMenu', which is defined after this, so cant be called directly
    const availableItems = !selectedItems
      ? items
      : items?.filter(
          (item) =>
            ![
              ...selectedItems.map(itemToString),
              itemToString(selectedItem),
            ].includes(itemToString(item)),
        );
    // If onSelect is passed. Else the component is ran as an uncontrolled input.
    if (onSelect) {
      onSelect(selectedItem);

      // Could work as single select as well. No use cases for now.
      if (!isSingleSelect) {
        setState({
          items: availableItems,
          value: '',
        });
        closeMenu();
      }
    } else {
      setState({
        items: availableItems,
        value: itemToString(selectedItem),
      });
      closeMenu();
    }
  };

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    closeMenu,
  }: UseComboboxReturnValue<Option> = useCombobox<Option>({
    items: inputItems,
    inputValue: value,
    onInputValueChange: ({ inputValue }) => {
      setState({
        items: getFilteredItems(
          { items, itemToString },
          inputValue,
          selectedItems,
        ),
        value: inputValue || '',
      });
    },
    onStateChange: ({ type, selectedItem }) => {
      // onStateChange doesnt work without onSelectedItemChange so it can't be merged to this
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          if (selectedItem) {
            onItemSelect(selectedItem, closeMenu);
          }
          break;
        default:
          break;
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        onItemSelect(selectedItem, closeMenu);
      }
    },
    itemToString,
  });

  const onFocus: FocusEventHandler<HTMLInputElement> = () => {
    if (!hideOptionsOnFocus) {
      setState((s) => ({
        ...s,
        items: getFilteredItems(
          { items, itemToString },
          s.value,
          selectedItems,
        ),
      }));
      openMenu();
    }
  };

  if (!labelText && !children) {
    throw new TypeError('Provide children if not labelText');
  }
  const inputProps: Partial<InputProps> & { value: string } = getInputProps({
    name,
    id: name,
    disabled,
    placeholder,
  });

  return (
    <Box pos="relative" {...containerProps}>
      {children ? (
        children({
          getInputProps,
          getOuterProps: () => getComboboxProps(),
          items: inputItems,
          openMenu,
          getLabelProps,
          disabled,
        })
      ) : (
        <FormControl>
          <FormLabel {...getLabelProps({ htmlFor: name })}>
            {labelText}
          </FormLabel>
          <Box {...getComboboxProps()}>
            <Input {...inputProps} onFocus={onFocus} />
          </Box>
        </FormControl>
      )}
      <List
        borderRadius="md"
        pos="absolute"
        left="0"
        right="0"
        mt="1"
        bg="white"
        zIndex="25"
        maxHeight="200px"
        overflowY="auto"
        boxShadow="md"
        {...getMenuProps()}
        {...listProps}
      >
        {isOpen && !disabled
          ? inputItems?.slice(0, 50)?.map((item: Option, index, arr) => (
              <ListItem
                key={item.id}
                borderTopRadius={!index ? 'md' : ''}
                borderBottomRadius={arr.length - 1 === index ? 'md' : ''}
                bg={highlightedIndex === index ? 'gray2' : 'transparent'}
                cursor="pointer"
                {...getItemProps({
                  index,
                  item,
                })}
                {...itemProps}
              >
                {renderOption
                  ? renderOption(item, inputProps.value)
                  : item.name}
              </ListItem>
            ))
          : null}
      </List>
    </Box>
  );
};

export default AutoSuggest;
