import { ParsedUrlQuery } from 'querystring';
import {
  isLocationFilterSet,
  listingParams,
  parseLocationToFilters,
} from '@/lib/listingUtils';
import { FilterValues } from '@/types/listing';

// resolve multi value as in resolve checkbox value, which can be "foo, bar, foobar" or just "foo"
const resolveMultiValue = (
  value: string,
  isChecked: boolean,
  currentValue?: string | string[],
) => {
  if (!currentValue) {
    return isChecked ? value : undefined;
  }

  const existing = Array.isArray(currentValue)
    ? currentValue
    : currentValue.split(',');
  // value is set and this input is in the bag too
  if (existing.includes(value)) {
    return isChecked ? existing : existing.filter((v) => v !== value);
  }

  // value is set, but this one's not in the bag
  return isChecked ? [...existing, value] : existing;
};

const allowedParams = [...listingParams, 'location'];

/* Filters use URL/queryParams to store its state. So, in order to prevent state duplication and because we dont, for reasons, update
 * the URL onChange, we use the uncontrolled way. This function gets all the relevant values from the filters form.
 * It also converts the 'location' string into 'municipality' and 'province'.
 */
export const getFormFilters = (
  { elements }: HTMLFormElement,
  query?: ParsedUrlQuery,
): FilterValues => {
  const showReadOnly = !query;
  return (
    Array.from(elements) as (
      | HTMLInputElement
      | HTMLSelectElement
      | HTMLButtonElement
    )[]
  ).reduce<Partial<FilterValues>>(
    (acc, { name, value, type, disabled, ...input }) => {
      // TODO implement filtering of Filters once another PR is merged as well as other things from it
      if (
        !value ||
        (showReadOnly && disabled) ||
        !allowedParams.includes(name)
      ) {
        return acc;
      }

      if (name === 'location') {
        const resolvedLocation = parseLocationToFilters(value);
        if (!resolvedLocation) {
          return acc;
        }
        // Dont stack locations if they are already set in query params
        if (query && isLocationFilterSet(resolvedLocation, query)) {
          return acc;
        }

        return {
          ...acc,
          [resolvedLocation.type]: resolvedLocation.value,
        };
      }
      const nameOfFilter = name as keyof FilterValues;
      const updatedValue =
        type === 'checkbox'
          ? resolveMultiValue(
              value,
              (input as { checked: boolean })?.checked,
              acc[nameOfFilter] || (query && query[nameOfFilter]),
            )
          : value;
      if (typeof updatedValue === 'undefined') {
        return acc;
      }

      return { ...acc, [nameOfFilter]: updatedValue };
    },
    {},
  );
};

export default { getFormFilters };
