import { useEffect, useState, ReactNode, useRef, useMemo } from 'react';
import { useRouter } from 'next/router';
import {
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Grid,
  GridItem,
  IconButton,
  Icon,
  HStack,
  VStack,
  Text,
  Select,
  InputGroup,
  InputRightElement,
  Button,
  Checkbox,
  FlexProps,
  List,
  ListItem,
} from '@chakra-ui/react';
import { MagnifyingGlass, X } from '@phosphor-icons/react';
import { useTranslation } from 'next-i18next';
import sortBy from 'lodash.sortby';
import {
  municipalities,
  provinces,
  getRegionName,
  getRegionValue,
  Region,
  RegionType,
} from '@/lib/regions';
import { useLayout } from '@/lib/hooks/useApp';
import {
  widenedListingTerms,
  LOCATION_DELIMITER,
  getLocationFilters,
} from '@/lib/listingUtils';
import { ListingSearchPanel } from '@/types';
import { Filters, FilterValues } from '@/types/listing';
import AutoSuggest, {
  Option as AutoSuggestOption,
} from '@/components/frontend/Form/AutoSuggest';
import TilavahtiModalButton from '../../TilavahtiForm';

const features = [
  { value: 'plot', label: 'Kaava' },
  { value: 'road', label: 'Tie perille' },
  { value: 'shoreline', label: 'Ranta' },
];

// const watersideProperties = [
//   { value: 'none', label: 'Ei rantaa' },
//   { value: 'beach', label: 'Oma ranta' },
//   { value: 'access', label: 'Rantaoikeus' },
//   { value: 'permission', label: 'Oikeus vesialueisiin' },
// ];

export interface Props extends ListingSearchPanel {
  children: (field: ReactNode) => ReactNode;
  getCurrentFilters?: () => Filters | null;
  alwaysExpanded?: boolean;
  onAdd?: (filter: FilterValues) => Promise<void>;
  onRemove?: (filter: FilterValues) => Promise<void>;
  controlledFilters?: Filters;
}

const normaliseLocationValue = (str?: string) =>
  str?.replace(/\s/g, '')?.toLowerCase() || '';

const sanitizeLocation = (str?: string | string[]) =>
  (Array.isArray(str)
    ? str.map((s) => normaliseLocationValue(decodeURIComponent(s)))
    : [normaliseLocationValue(decodeURIComponent(str || ''))]
  ).filter((l): l is string => !!l);

const regionTypes: RegionType[] = ['municipality', 'province'];
const isLocationType = (type?: string): type is RegionType =>
  !!(type && regionTypes.includes(type as RegionType));

const locationOptionToFilter = ({
  value,
  type,
}: AutoSuggestOption): null | { [key in RegionType]?: string } => {
  if (!value || !isLocationType(type)) {
    return null;
  }
  const [province, municipality] = value.split(LOCATION_DELIMITER);

  return {
    [type]: type === 'municipality' ? municipality : province,
  };
};

/*
 * Basically merges provinces and municipalities into one list
 * Municipalities have provinces as "tags" to help filtering them by eg. province too
 */
const createLocationOptions = (
  locale = 'fi',
  enforcedFilters: ListingSearchPanel['enforcedFilters'],
) => {
  let availableMunicipalities: Region[] = municipalities;
  let availableProvinces: Region[] = provinces;
  const regionsOnlyForSorting: number[] = [];

  const { municipality: enforcedMunicipality, province: enforcedProvince } =
    enforcedFilters || {};

  const enforcedProvinces = sanitizeLocation(enforcedProvince);
  const enforcedMunicipalities = sanitizeLocation(enforcedMunicipality);

  /* if location is enforced, obey it, but...
   * if Muncipality is enforced and no province is enforced. Use filter as "only option"
   *
   * Alternative if province is enforced, allow municipalities of that province only as option. Possibly
   * enforced municipalities then in this context, if they are of the province, are to be filtered out.
   */
  const isOnlyMunicipalityEnforced =
    enforcedMunicipalities.length && !enforcedProvinces.length;
  if (isOnlyMunicipalityEnforced) {
    availableMunicipalities = availableMunicipalities.filter((m) =>
      enforcedMunicipalities.includes(
        normaliseLocationValue(getRegionValue(m)),
      ),
    );

    availableProvinces = availableProvinces.filter(({ id }) =>
      availableMunicipalities.find(({ provinceId }) => id === provinceId),
    );
  } else if (enforcedProvinces.length) {
    availableProvinces = availableProvinces.filter((m) =>
      enforcedProvinces.includes(normaliseLocationValue(getRegionValue(m))),
    );

    availableMunicipalities = availableMunicipalities.filter(({ provinceId }) =>
      availableProvinces.find(({ id }) => id === provinceId),
    );

    if (enforcedMunicipalities.length) {
      const extraMunicipalities = municipalities.filter((m) =>
        enforcedMunicipalities.includes(
          normaliseLocationValue(getRegionValue(m)),
        ),
      );

      availableMunicipalities = [
        ...new Set<Region>([
          ...availableMunicipalities,
          ...extraMunicipalities,
        ]),
      ] as Region[];

      const extraProvinces = extraMunicipalities.reduce<Region[]>(
        (acc, { provinceId }) => {
          if (
            !provinceId ||
            availableProvinces.find(({ id }) => id === provinceId)
          ) {
            return acc;
          }
          const found = provinces.find(({ id }) => id === provinceId);
          if (!found) {
            return acc;
          }
          regionsOnlyForSorting.push(found.id);
          return [...acc, found];
        },
        [],
      );

      availableProvinces = [...availableProvinces, ...extraProvinces];
    }
  }

  /*
   * Municipalities and provinces are separate arrays. What we wanna do is print them like
   *
   * Province A
   * Muncipality 1 of province A
   * Muncipality 2 of province A
   * Province B
   * Muncipality 1 of province B
   * etc...
   *
   * Hence we create a map of municipalities.
   */
  const municipalitiesMap = availableMunicipalities.reduce<{
    [id: number]: Omit<Region, 'provinceId'>[];
  }>(
    (acc, { provinceId, name, id, type }) =>
      !provinceId
        ? acc
        : {
            ...acc,
            [provinceId]: acc[provinceId]
              ? [...acc[provinceId], { name, id, type }]
              : [{ name, id, type }],
          },
    {},
  );

  return sortBy(availableProvinces, `name.${locale}`).reduce<
    AutoSuggestOption[]
  >((acc, { id, name, type }) => {
    const mList = municipalitiesMap[id];
    const provinceItem = {
      name: getRegionName({ name }, locale),
      value: getRegionValue({ name }),
      type,
      id,
    };

    // If the province has no municipalities, just return the province
    if (!mList?.length) {
      // If enforced by municipality only, dont return the province at all
      if (isOnlyMunicipalityEnforced || regionsOnlyForSorting.includes(id)) {
        return acc;
      }
      return [...acc, provinceItem];
    }

    const sortedMunicipalityOptions = sortBy(mList, `name.${locale}`).map(
      ({ name: mName, id: mId, type: mType }) => ({
        name: getRegionName({ name: mName }, locale),
        value: `${provinceItem.value}${LOCATION_DELIMITER}${getRegionValue({
          name: mName,
        })}`,
        tags: [provinceItem.name], // NOTE here could be zip code as well. Tags are used for searching by user input
        id: `${id}-${mId}`,
        type: mType,
      }),
    );

    if (availableMunicipalities.length === 1) {
      // If there's only 1 available option for municipality, dont make the province available at all
      return [...acc, ...sortedMunicipalityOptions];
    }

    // If enforced by municipality only, dont return the province at all
    if (isOnlyMunicipalityEnforced || regionsOnlyForSorting.includes(id)) {
      return [...acc, ...sortedMunicipalityOptions];
    }
    return [...acc, provinceItem, ...sortedMunicipalityOptions];
  }, []);
};

/*
 * Gets current locations as Options. Falls back to search if no location found.
 * Considers enforced location filters too
 */
const getCurrentLocations = (
  currentFilters: ListingSearchPanel['currentFilters'],
  options: AutoSuggestOption[],
  enforcedFilters: ListingSearchPanel['enforcedFilters'],
): { locations?: AutoSuggestOption[]; search?: string } => {
  if (
    !currentFilters ||
    (!currentFilters.municipality && !currentFilters.province)
  ) {
    if (!currentFilters?.search || Array.isArray(currentFilters?.search)) {
      return {};
    }
    return { search: currentFilters.search };
  }

  const currentLocations = sanitizeLocation(
    Object.values(getLocationFilters(currentFilters)).flat(),
  );

  // array of option names that shouldn't be shown.
  const notShownLocations = enforcedFilters
    ? sanitizeLocation(Object.values(enforcedFilters).flat()).filter(
        (loc) => !currentLocations.includes(loc),
      )
    : [];

  // If both are defaulted, there's only one option
  if (options.length === 1) {
    const [firstOption] = options;
    // Check for enforcedFilters. If they have the first and only option in them, dont use as default location
    if (notShownLocations.includes(normaliseLocationValue(firstOption.name))) {
      return {};
    }
    return { locations: [firstOption] };
  }

  const locationsArray = currentLocations.filter((l): l is string => !!l);

  return {
    locations: options.filter(({ value = '' }) => {
      // Value includes province maybe both and name is translated. Use value
      const [provinceValue, municipalityValue] =
        value?.split(LOCATION_DELIMITER);

      return locationsArray?.includes(
        normaliseLocationValue(municipalityValue || provinceValue),
      );
    }),
  };
};

const SearchFilters = ({
  propertyTypes,
  currentFilters: defaultFilters,
  enforcedFilters,
  children,
  getCurrentFilters,
  alwaysExpanded = false,
  onAdd,
  onRemove,
  controlledFilters,
  ...rest
}: Omit<FlexProps, keyof Props> & Props) => {
  const currentFilters = controlledFilters || defaultFilters;
  const { t } = useTranslation(['frontoffice', 'backoffice']);
  const { setOverlap, overlap } = useLayout();
  const locationListRef = useRef<HTMLDivElement>(null);
  const { locale } = useRouter();
  const isWidenedTermsInUse =
    alwaysExpanded ||
    (!currentFilters
      ? false
      : !!Object.entries(currentFilters).find(
          ([k, v]) => v && widenedListingTerms.includes(k as keyof Filters),
        ));
  const [showMoreTerms, setShowMoreSearchTerms] = useState(isWidenedTermsInUse);

  const locationOptions = createLocationOptions(locale, enforcedFilters);

  const onShowMore = () => {
    if (!showMoreTerms) {
      setOverlap('expanded');
      setShowMoreSearchTerms(true);
    }
  };

  const setFocusToLastOfLocationList = () => {
    // Programmatically move focus to improve UX.
    if (locationListRef?.current) {
      // Set focus to last selected location button/tag. Done only if the whole block is rendered and button exists
      const lastBtn = locationListRef.current.querySelector<HTMLButtonElement>(
        'li:last-of-type button',
      );
      if (lastBtn) {
        lastBtn.focus();
      }
    } else {
      /* Location list is no longer rendered, so move focus back to the Autosuggest input.
       * Could be done with a ref, but dont see any point for it. The id (location) used to select the input, is passed from this component as a name too.
       */
      const autoSuggestEl =
        document?.querySelector?.<HTMLInputElement>('input#location');
      if (autoSuggestEl) {
        autoSuggestEl.focus();
      }
    }
  };

  useEffect(() => {
    if (showMoreTerms && overlap !== 'expanded') {
      setOverlap('expanded');
    }
  }, [overlap, showMoreTerms, setOverlap]);

  useEffect(() => {
    return () => {
      if (showMoreTerms) {
        setOverlap(null);
      }
    };
  }, [showMoreTerms, setOverlap]);

  const isPropertyTypesLocked = !!enforcedFilters?.property_type;
  const { search, locations: currentLocations } = getCurrentLocations(
    currentFilters,
    locationOptions,
    enforcedFilters,
  );

  const onLocationSelect = async (opt?: AutoSuggestOption | null) => {
    if (opt && onAdd) {
      const filter = locationOptionToFilter(opt);
      if (filter) {
        await onAdd(filter);
        setFocusToLastOfLocationList();
      }
    }
  };

  const onLocationRemove = async (opt?: AutoSuggestOption | null) => {
    if (opt && onRemove) {
      const filter = locationOptionToFilter(opt);
      if (filter) {
        await onRemove(filter);
        setFocusToLastOfLocationList();
      }
    }
  };

  const isLocationsListShown = !!(currentLocations && onRemove);

  const tilavahtiDefaultValues = useMemo(
    () => (getCurrentFilters ? { filters: getCurrentFilters() } : undefined),
    [getCurrentFilters],
  );

  const topRowContent = (
    <>
      <GridItem>
        <AutoSuggest
          name="location"
          items={locationOptions}
          itemToString={(option: AutoSuggestOption | null) =>
            option?.value || ''
          }
          placeholder={t('location_placeholder', 'Esim. kunta, maakunta...')}
          labelText={t('search', 'Haku')}
          itemProps={{
            px: 2,
            py: 1,
            mb: 1,
            _selected: { bg: 'highlightBackground' },
          }}
          defaultValue={search}
          selectedItems={currentLocations}
          disabled={locationOptions.length === 1}
          onSelect={onAdd && onLocationSelect}
        />
      </GridItem>
      {isLocationsListShown ? (
        <GridItem
          d="flex"
          flexWrap="wrap"
          as={List}
          gridColumnGap="2"
          gridRow="2"
          gridColumn="1/-1"
          mb="4"
          ref={locationListRef}
        >
          {currentLocations?.map((opt) => (
            <ListItem mb="2" key={opt.id}>
              <Button
                onClick={async () => {
                  await onLocationRemove(opt);
                }}
                rightIcon={<X />}
                size="sm"
              >
                {opt.name}
              </Button>
            </ListItem>
          ))}
        </GridItem>
      ) : null}
      <GridItem>
        <FormControl>
          <FormLabel>{t('property_type', 'Kohdetyyppi')}</FormLabel>
          <Select
            id="property_type"
            defaultValue={currentFilters?.property_type}
            name="property_type"
            placeholder={t('select_property_type', 'Kaikki')}
            isDisabled={isPropertyTypesLocked}
          >
            {propertyTypes?.map((opt) => (
              <option key={opt.value} value={String(opt.value)}>
                {t(`backoffice:property_type_values.${opt.value}`)}
              </option>
            ))}
          </Select>
        </FormControl>
      </GridItem>

      <GridItem>
        <FormControl>
          <FormLabel>{t('area', 'Pinta-ala')}</FormLabel>
          <HStack spacing={3}>
            <InputGroup>
              <Input
                id="area_min"
                defaultValue={currentFilters?.area_min}
                name="area_min"
                type="number"
                pattern="[0-9]*"
                placeholder="min"
                min={0}
                aria-label={t('min_area', 'Min. pinta-ala (hehtaareissa)')}
              />
              <InputRightElement children="ha" />
            </InputGroup>
            <Text>–</Text>
            <InputGroup>
              <Input
                id="area_max"
                defaultValue={currentFilters?.area_max}
                name="area_max"
                type="number"
                pattern="[0-9]*"
                placeholder="max"
                min={0}
                aria-label={t('max_area', 'Maks. pinta-ala (hehtaareissa)')}
              />
              <InputRightElement children="ha" />
            </InputGroup>
          </HStack>
        </FormControl>
      </GridItem>

      <GridItem>
        <FormControl>
          <FormLabel>{t('price', 'Hinta')}</FormLabel>
          <HStack spacing={3}>
            <InputGroup>
              <Input
                id="price_min"
                defaultValue={currentFilters?.price_min}
                name="price_min"
                type="number"
                pattern="[0-9]*"
                placeholder="min"
                min={0}
                aria-label={t('min_price', 'Min. hinta')}
              />
              <InputRightElement children="€" />
            </InputGroup>
            <Text>–</Text>
            <InputGroup>
              <Input
                id="price_max"
                defaultValue={currentFilters?.price_max}
                name="price_max"
                type="number"
                pattern="[0-9]*"
                placeholder="max"
                min={0}
                aria-label={t('max_price', 'Maks. hinta')}
              />
              <InputRightElement children="€" />
            </InputGroup>
          </HStack>
        </FormControl>
      </GridItem>
    </>
  );

  return (
    <Flex
      backgroundColor="white"
      flexDirection="column"
      borderRadius="lg"
      boxShadow="md"
      px={[5, null, 7, 10]}
      pt={[4, null, 6, 8]}
      pb={[5, null, 7, 10]}
      zIndex="10"
      {...rest}
    >
      {children(
        <>
          <Flex as="header" justifyContent="space-between" flexWrap="wrap">
            <Heading as="h2" fontSize="2xl" mb="5">
              {t('search_listings', 'Hae kohteita')}
            </Heading>
            {showMoreTerms ? null : (
              <Button variant="link" onClick={onShowMore} mb="5">
                {t('more_search_terms', 'Lisää hakuehtoja')}
              </Button>
            )}
          </Flex>

          <Grid
            templateColumns={['1fr', '1fr', '1fr 1fr', `repeat(4, 1fr)`]}
            gap={isLocationsListShown ? 2 : 6}
          >
            {topRowContent}
            {showMoreTerms ? (
              <>
                <GridItem
                  gridRow={
                    isLocationsListShown ? [null, null, null, 3] : undefined
                  }
                >
                  <FormControl>
                    <FormLabel>{t('features', 'Ominaisuudet')}</FormLabel>
                    <VStack spacing={3} alignItems="flex-start">
                      {features.map(({ label, ...input }) => {
                        const isDefaultChecked = Array.isArray(
                          currentFilters?.features,
                        )
                          ? currentFilters?.features.includes(input.value)
                          : currentFilters?.features === input.value;

                        return (
                          <Checkbox
                            colorScheme="green"
                            name="features"
                            key={input.value}
                            defaultIsChecked={isDefaultChecked}
                            {...input}
                          >
                            {t(`features_${input.value}`, label)}
                          </Checkbox>
                        );
                      })}
                    </VStack>
                  </FormControl>
                </GridItem>
                {/*
                // TODO: Removed for now since they don't work properly
                <GridItem>
                  <FormControl>
                    <FormLabel>{t('waterside', 'Ranta')}</FormLabel>
                    <VStack spacing={3} alignItems="flex-start">
                      {watersideProperties.map(({ label, ...input }) => {
                        const isDefaultChecked = Array.isArray(
                          currentFilters?.waterside,
                        )
                          ? currentFilters?.waterside.includes(input.value)
                          : currentFilters?.waterside === input.value;

                        return (
                          <Checkbox
                            colorScheme="green"
                            name="waterside"
                            key={input.value}
                            defaultIsChecked={isDefaultChecked}
                            {...input}
                          >
                            {t(`waterside_${input.value}`, label)}
                          </Checkbox>
                        );
                      })}
                    </VStack>
                  </FormControl>
                </GridItem>
                */}
              </>
            ) : null}
          </Grid>
          <Flex
            as="footer"
            justifyContent="space-between"
            alignItems="center"
            gridColumnGap="4"
            pt="8"
            mb="-3"
            flexWrap="wrap"
          >
            <Flex flexWrap="wrap">
              <Button
                size="lg"
                type="submit"
                colorScheme="primary"
                mb="3"
                mr="3"
              >
                {t('show_listings', 'Näytä kohteet')}
              </Button>
              <TilavahtiModalButton
                size="lg"
                mb="3"
                variant="alt"
                defaultValues={tilavahtiDefaultValues}
                propertyTypes={propertyTypes ?? []}
              >
                {t('assign_listing_watch', 'Aseta tilavahti')}
              </TilavahtiModalButton>
            </Flex>
          </Flex>
        </>,
      )}
    </Flex>
  );
};

export default SearchFilters;
